<template>
  <v-list-item v-if="projectHasAlm">
    <v-list-item-title>
      <a @click="almSync">
        <v-card class="pa-2 mt-3 elevation-0">
          <v-icon class="mr-2 mt-n1">mdi-sync</v-icon>
          ALM Sync
        </v-card>
      </a>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'AlmSync',
  props: ['currentTable', 'selectedRows'],
  computed: {
    currentRow() {
      return this.currentTable.data.find(x => x.n_id === Object.keys(this.selectedRows)[0]);
    },
    projectHasAlm() {
      if (this.currentRow) return this.currentRow.alm;
      return false;
    },
  },
  methods: {
    almSync() {
      this.services.alm.sync(this.currentRow['c_domain'], () => {
        this.$store.commit('updateTableDialog', {
          active: true,
          type: 'Ok',
          title: 'Request sent',
          text: `ALM sync for project "${this.currentRow['c_name']}" has started.`,
          width: 350,
        });
      }, (error) => {
        this.$store.commit('notification', {
          text: `ALM sync could not start. Please try again later or contact Acctopus. ${error}`,
          length: 3000,
        });
      });
    },
  },
};
</script>

<style scoped>

</style>